import React from "react"

import Layout from "../components/layout"
import Image from "../components/image"
import SEO from "../components/seo"

const IndexPage = ({ data }) => (
  <Layout>
    <SEO title="Home" />
    <h1>Welcome to my site</h1>
    <p>
      Not much here yet. Just kind of a place to mess around with things,
      really. Might be something useful here sometime.
    </p>
    <p>
      You could also take a look at{" "}
      <a href="https://snydergd.xyz">snydergd.xyz</a>
    </p>
    <div style={{ maxWidth: `300px`, marginBottom: `1.45rem` }}>
      <Image />
    </div>
    <p>Thanks for stopping by!</p>
    <a rel="me" href="https://mstdn.io/@snydergd">.</a>
  </Layout>
)

export default IndexPage
